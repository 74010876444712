import * as React from "react";
import Helmet from "react-helmet";
import "../styles/about.scss";

import {
  Clients,
  CaseStudy,
  Feature,
  Form,
  Footer,
  Team,
  Values
} from "../components";
const soundImg = require("../assets/images/pg_about.jpg");


const seo = {
  title: `About us`,
  description: `Get to know all about us, our team and values.`,
}

// type Props = {};
export default () => {
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description}/>
        <meta name="twitter:description" content={seo.description} />
      </Helmet>
      <Feature.Mast
        title={seo.description}
        image={soundImg}
      />
      {/* <Feature.Services />  */}
      <Values.Full />
      <Team.Full />
      <Clients.Full />
      <CaseStudy.Min />
      <Form.Contact />
      <Footer /> 
    </>
  );
};

{
  /* <div className="aboutPage"> */
}
{
  /* <div className="container">
          <div className="h0 aboutPage__intro">
            <p>
              <span className="hghlt">Team Ubuntu</span> is composed of young,
              vibrant, versatile, purpose driven individuals that heed to
              clients’ specifications and expectations. The team’s ability to{" "}
              <span className="brght">
                adjust quickly, efficiently, and cost effectively
              </span>{" "}
              is the defining note of our symphony at Ubuntu Events Technology.
              Keeping up with the latest trends in the event technology world is
              one of our key driving factors.
            </p>
            <p>
              Our culture matters and it is reflected in the team work. We are
              passionate about every new venture, our profession and value
              clients' perspectives. We realise that every event is a new
              challenge, but also another opportunity to improve on our services
              and grow as a company. In doing so, we become trandsetters and are
              happy to rather compete with ourselves.
            </p>
          </div>
        </div> */
}
